.App {
  text-align: center;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: full-spin infinite 20s linear;
  }
  .spin {
    animation: full-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  cursor:pointer;
  text-decoration: none;

}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

@keyframes full-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/*//////////////////////////
basic-display-table*/
table.basic-display-table{
	width:100%;
	border:none;
	border-collapse:collapse;
	font-weight: 400;
}
table.basic-display-table > thead > tr > th {
	background-color:#848484;
	color:white;
	border:none;
	margin:none;
	padding:8px;
	font-weight: 400;
}
table.basic-display-table > tbody > tr > td {
	background-color:#F9F9F9;
	border:none;
	border-bottom:solid 1px #E0E0E0;
	margin:none;
	padding:8px;
	font-weight: 400;
}
table.basic-display-table > tbody > tr:nth-child(2n) > td {
	background-color:white;
}
/*/basic-display-table
/////////////////////////////////////////////*/